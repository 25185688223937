<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 justify-content-end">
                    <div class="position-abs">
                        <div>
                            <input type="text" class="form-control" (keyup)="searchFilter($event,'search')"
                                placeholder="Search.">
                        </div>
                        <div>
                            <select class="form-control category-filter" (change)="searchFilter($event,'category')">
                                <option hidden value="" disabled selected>-</option>
                                <option value="">All.</option>
                                <option *ngFor="let data of categoryData" value="{{data['_id']}}">{{data['category']}}.
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">

                    <div class="accordion" id="accordionFlushExample">
                        <ng-container *ngIf="!loader">
                            <div class="accordion-item" *ngFor="let data of faqData;let i = index;">
                                <h2 class="accordion-header">
                                    <button class="accordion-button " type="button" data-bs-toggle="collapse"
                                        [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                                        [attr.aria-controls]="'flush-collapse'+i">
                                        {{data.question}}
                                    </button>
                                </h2>
                                <div id="flush-collapse{{i}}" class="accordion-collapse collapse"
                                    [ngClass]="i == 0 ? 'show' : ''" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="prdouct-faq">
                                            <p>{{data.answer}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center" *ngIf="faqDataPaginations.length == 0">
                                {{filterCategory? 'No data found.':'Please select a category.'}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="loader">
                            <div class="d-flex justify-content-center">
                                Processing <span class="Loaders"></span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

    </section>
</div>