<footer class="base_footer">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-sm-4">
                <div class="footer-text">
                    <p>Email: <a href="mailto:info@realworld.fi">info@realworld.fi</a></p>
                    <ul class="social-links">
                        <li ><a  href="https://discord.com/invite/6xEX48jsVX" target="_blank"><i  class="bi bi-discord fs-6"></i></a></li>
                        <li ><a  href="https://twitter.com/realworld_fi" target="_blank"><i  class="bi bi-twitter-x fs-6"></i></a></li>
                        <li ><a  href="https://www.linkedin.com/company/real-world-finance/" target="_blank"><i  class="bi bi-linkedin fs-6"></i></a></li></ul>
                </div>
            </div>
            <div class="col-lg-6 col-sm-8">
                <!-- <p class="text-right">RealWorld International Ventures Ltd.</p>
                <p class="text-right">Reg. Number: C 57659. Hamilton Development, unit B, Charlestown, Nevis, West Indies.</p> -->
                <div class="d-flex align-items-center justify-content-end gap-3">
                    <a href="{{privacyPolicyUrl}}" target="_blank">Privacy policy.</a>
                    <a>|</a>
                    <a href="{{userAggrementUrl}}" target="_blank">User agreement.</a>
                </div>
            </div>
            
        </div>
    </div>
    <div class="backtotop" (click)="bottomtotop()">
        <i class="bi bi-arrow-up"></i>
    </div>
</footer>