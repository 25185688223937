
<div class="main">
    <section class="signup">
        <div class="container-fluid pl-0">
            <div class="row ">
                <div class="col-lg-6 col-md-6 d-md-block d-none">
                    <div class="left-view-slider">
                        <div class="view_carousel">
                            <owl-carousel-o [options]="customOptions">
                                <ng-template carouselSlide>
                                    <img src="assets/images/signup.png" alt="img" class="img-fluid">
                                    <div class="slidetext">
                                        <h2>Multi-asset marketplace. </h2>
                                        <p>Trade multiple alternative assets in the luxury commodity and collectibles category.</p>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <img src="assets/images/signup-asset.png" alt="img" class="img-fluid">
                                    <div class="slidetext">
                                        <h2>Advanced dashboard.</h2>
                                        <p>Keep track of your positions and activity with our multipurpose Dashboard.</p>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <img src="assets/images/signup-lend.png" alt="img" class="img-fluid">
                                    <div class="slidetext">
                                        <h2>Lend and borrow against your assets</h2>
                                        <p>Leverage your position or gain yield lending to other users.</p>
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="mailverfication" *ngIf="!isEmailVerified && !emailVerificationError" >
                        <h3>Check Your Email.</h3>
                        <h4 *ngIf="!fromLoginPage">We have sent you an email to verify. Check your inbox for more information about the verification.</h4>
                        <h4 *ngIf="fromLoginPage">Your email is not verified yet from the email we have sent to you. Please verify it to continue.</h4>
                        <form action="">
                            <div class="form-group">
                                <label for="email">Email Address</label>
                                <input type="text" id="email" class="form-control" [value]="user['email']" readonly>
                            </div>
                            <p>Didn't receive the email?
                              <a class="cursor-pointer" [class.disabled]="isDisabled"
                              [attr.disabled]="isDisabled ? true : null" (click)="resendVerificationEmail()">Resend email.</a>
                            </p>
                            <a routerLink="/sign-up" class="backbutton">Back.</a>
                        </form>
                    </div>
                    <div class="mailverfication" *ngIf="isEmailVerified && !emailVerificationError" >
                        <div class="text-center">
                            <img src="assets/images/success-3.svg" alt="success" width="100" height="100" class="mb-3">
                            <h3>Account verified.</h3>
                            <h4 >Your account has been successfully verified. You can now enjoy full access to all features and services.</h4>
                            <a routerLink="/sign-in" class="backbutton  w-90">Continue.</a>
                        </div>
                    </div>
                    <div class="mailverfication" *ngIf="emailVerificationError" >
                      <div >
                        <!-- <img src="assets/images/error-1.svg" alt="success" width="100" height="100" class="mb-3"> -->

                            <h3>Email is expired.</h3>
                          <form action="">
                            <div class="form-group">
                                <label for="email">Email Address</label>
                                <input type="text" id="email" class="form-control" [value]="user['email']" readonly>
                            </div>

                          <h4 >Your Verification Email is expired. Please click
                            <a class="cursor-pointer" [class.disabled]="isDisabled"
                            [attr.disabled]="isDisabled ? true : null" (click)="resendVerificationEmail()">here</a>
                           to get new verification email.</h4>

                            <a routerLink="/sign-up" class="backbutton">Back.</a>
                        </form>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </section>
</div>
