
<div class="main">
    <section class="signup">
        <div class="container-fluid pl-0">
            <div class="row align-items-lg-center">
                <div class="col-lg-6 col-md-6 d-md-block d-none">
                    <div class="left-view-slider">
                        <div class="view_carousel">
                            <owl-carousel-o [options]="customOptions">
                                <ng-template carouselSlide>
                                    <img src="assets/images/signup.png" alt="img" class="img-fluid">
                                    <div class="slidetext">
                                        <h2>Multi-asset marketplace. </h2>
                                        <p>Trade multiple alternative assets in the luxury commodity and collectibles category.</p>
                                    </div>
                                </ng-template>  
                                <ng-template carouselSlide>
                                    <img src="assets/images/signup-asset.png" alt="img" class="img-fluid">
                                    <div class="slidetext">
                                        <h2>Advanced dashboard.</h2>
                                        <p>Keep track of your positions and activity with our multipurpose Dashboard.</p>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <img src="assets/images/signup-lend.png" alt="img" class="img-fluid">
                                    <div class="slidetext">
                                        <h2>Lend and borrow against your assets</h2>
                                        <p>Leverage your position or gain yield lending to other users.</p>
                                    </div>
                                </ng-template>  
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 px-4">
                    <div class="right-view-form">
                        <div class="col-lg-12">
                            <div class="authheader">
                                <div class="d-flex justify-content-center">
                                    <div><a [routerLink]="['/']" (click)="routingHome()"><img src="assets/images/logo.png" width="250px" class="img-fluid"></a></div>
                                </div>
                            </div>
                        </div>
                        <form class="col-lg-12 mb-4" [formGroup]="forgotPasswordForm">
                            <h4>Reset your password.</h4>
                            <p>Enter the email address associated with your account and we will send you a link to reset your password.</p>
                            <div class="form-group mb-5">
                                <label for="email">Email address.</label>
                                <input type="email" class="form-control" id="email" placeholder="Ex: johnabraham@mail.com." formControlName="email">
                                <div class="input--error" *ngIf="forgotPasswordFormSubmitted && forgotPasswordFormControls['email'].errors?.['required']">
                                    Email is required.*
                                </div>
                                <div class="input--error" *ngIf="forgotPasswordFormSubmitted && forgotPasswordFormControls['email'].errors?.['email']">
                                    Email must be valid.*
                                </div>
                                <div class="input--error" *ngIf="forgotPasswordFormSubmitted && forgotPasswordFormControls['email'].errors?.['forbidUppercase']">
                                    Capital letters not allowed.*
                                </div>
                            </div>
                            <div class="gap-4 mb-4">
                                <button class="wallet-connect" type="submit" appDebounceClick (debounceClick)="forgotPassword()" [disabled]="forgotPasswordFormProcessing" [ngClass]="{'disabled': forgotPasswordFormProcessing}">Proceed.</button>
                            </div>
                            <div class="text-left"><span>Already have an account? </span><a class="pl-2" [routerLink]="['/sign-in']">Log in.</a></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>