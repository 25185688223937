<div class="main-wrap" >
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section" >
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 mx-auto">
                    <div class="borrow-card">
                        <h1 class="mb-3">Terms and conditions.</h1>
                        
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus delectus aliquid tenetur ullam, eaque illo quidem libero veritatis ab enim vel deleniti! Nostrum debitis non distinctio accusantium dignissimos nulla ex!</p>

                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus delectus aliquid tenetur ullam, eaque illo quidem libero veritatis ab enim vel deleniti! Nostrum debitis non distinctio accusantium dignissimos nulla ex!</p>

                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus delectus aliquid tenetur ullam, eaque illo quidem libero veritatis ab enim vel deleniti! Nostrum debitis non distinctio accusantium dignissimos nulla ex!</p>

                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus delectus aliquid tenetur ullam, eaque illo quidem libero veritatis ab enim vel deleniti! Nostrum debitis non distinctio accusantium dignissimos nulla ex!</p>

                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus delectus aliquid tenetur ullam, eaque illo quidem libero veritatis ab enim vel deleniti! Nostrum debitis non distinctio accusantium dignissimos nulla ex!</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus delectus aliquid tenetur ullam, eaque illo quidem libero veritatis ab enim vel deleniti! Nostrum debitis non distinctio accusantium dignissimos nulla ex!</p>

                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus delectus aliquid tenetur ullam, eaque illo quidem libero veritatis ab enim vel deleniti! Nostrum debitis non distinctio accusantium dignissimos nulla ex!</p>

                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus delectus aliquid tenetur ullam, eaque illo quidem libero veritatis ab enim vel deleniti! Nostrum debitis non distinctio accusantium dignissimos nulla ex!</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>